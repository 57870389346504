<template>
  <div class="h-full">
    <div class="suporte-cliente-dashboard">
      <div class="sheader">
        <h2 class="component-title">Console de Suporte</h2>
        <div class="btns">
          <u-btn v-if="clientStats.responsavel" class="sl-btn box-v2 text-grey btn-gerente hoverable" color="white" no-caps>
            <div class="person-avatar avatar inline hover-scale">
              <img class="" :src="clientStats.responsavel.photo" />
            </div>
            <div class="label">
              <div><span class="lbl-title">{{ firstLastname(clientStats.responsavel.name) }}</span></div>
              <div class="subtitle hide-on-hover">Responsável pela conta</div>
              <div class="subtitle show-on-hover" v-if="clientStats.responsavel.phone">{{clientStats.responsavel.phone|formataTelefone}}</div>
              <div class="subtitle" v-if="clientStats.responsavel.email">{{clientStats.responsavel.email}}</div>
            </div>
          </u-btn>
          <u-btn @click="$router.push({name: 'marketplace.dashboard'})" class="sl-btn box-v2 text-grey m-l" color="white" no-caps>
            <i class="fa-duotone fa-bags-shopping" /> <span>Marketplace</span>
          </u-btn>
          <u-btn @click="open" class="sl-btn box-v2 m-l" color="green" no-caps>
            <i class="fa-duotone fa-user-headset" /> <span>Nova Solicitação de Suporte</span>
          </u-btn>
        </div>
      </div>
      <div class="support-panel">
        <div class="scol1">
          <div class="box-v2">
            <suporte-cliente-tarefas-dashboard @clientStats="clientStatsEvt" @new="open" />
          </div>

          <div class="box-v2 m-t-lg">
            <suporte-cliente-release-dashboard v-if="showRelease" />
            <div class="flex items-center justify-center" v-else>
              Aguarde enquanto processamos o acesso à fila pública de demandas...
            </div>
          </div>
        </div>
        <div class="scol2">
          <div class="box-v2 dash-count" @click="$router.push({name: 'suporte.cliente.tarefas', query: {'filtrar': 'ativos'}})">
            <div class="sleft">
              <h3 class="box-title">Todos chamados ativos</h3>
              <div class="count">{{ clientStats.chamadosAtivos }}</div>
            </div>
            <div class="sright">
              <i class="fa-light fa-hourglass-clock" />
            </div>
          </div>
          <div class="box-v2 dash-count" @click="$router.push({name: 'suporte.cliente.tarefas', query: {'filtrar': 'usuario'}})">
            <div class="sleft">
              <h3 class="box-title">Meus chamados em aberto</h3>
              <div class="count">{{ clientStats.chamadosUsuarioLogado }}</div>
            </div>
            <div class="sright">
              <i class="fa-light fa-user-clock bg-orange" />
            </div>
          </div>
          <div class="box-v2 dash-count" @click="$router.push({name: 'suporte.cliente.tarefas', query: {'filtrar': 'atrasados'}})">
            <div class="sleft">
              <h3 class="box-title">Chamados em atraso</h3>
              <div class="count">{{ clientStats.tarefasAtrasadas }}</div>
            </div>
            <div class="sright">
              <i class="fa-light fa-sensor-triangle-exclamation bg-negative" />
            </div>
          </div>
          <div class="box-v2 dash-count">
            <div class="sleft">
              <h3 class="box-title">Versão do software</h3>
              <div class="count">{{ clientStats.softwareVersion }}</div>
            </div>
            <div class="sright">
              <i class="fa-light fa-hourglass-clock bg-black" />
            </div>
          </div>
          <news />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuporteClienteTarefasDashboard from "components/suporte/cliente/components/TarefasDashboard.vue"
import SuporteClienteReleaseDashboard from "components/suporte/cliente/components/Release.vue"
import News from "components/suporte/cliente/components/News.vue"
import criarTarefa from "components/suporte/cliente/window/criarTarefa"

export default {
  name: "SuporteClienteDashboard",
  data () {
    return {
      response: {},
      clientStats: {
        chamadosAtivos: 0,
        chamadosUsuarioLogado: 0,
        tarefasAtrasadas: 0,
        softwareVersion: '-',
        responsavel: null
      },
      showRelease: false
    }
  },
  components: {
    News,
    SuporteClienteReleaseDashboard,
    SuporteClienteTarefasDashboard
  },
  mounted() {
    setTimeout(() => {
      this.showRelease = true
    }, 2000)
  },
  methods: {
    clientStatsEvt (data) {
      this.clientStats = Object.assign(this.clientStats, data)
    },
    criarTarefa: criarTarefa,
    open () {
      this.criarTarefa()
    }
  }
}
</script>
